<!--我的比赛 (记分长、主办方功能模块)  -->
<template>
	<div class="page-content">
		<commonHeader ref="commonHeader"></commonHeader>
		<router-view />
	</div>
</template>

<script>
	import commonHeader from './components/header';
	export default {
		name: 'mymatchs',
		components: {
			commonHeader
		},
		data() {
			return {
				menusShow: false,
				currentMenuIndex: "1",
			};
		},
		watch: {
			$route() {
				if (this.$route.query && this.$route.query.menusShow) {
					this.$refs.commonHeader.init(this.$route.query.menusShow);
				} else {
					this.$refs.commonHeader.init(false);
				}
			}
		},
		created() {


		},
		mounted() {
			if (this.$route.query && this.$route.query.menusShow) {
				this.$refs.commonHeader.init(this.$route.query.menusShow);
			} else {
				this.$refs.commonHeader.init(false);
			}
			this.init();
		},
		destroyed() {

		},
		methods: {
			init() {
				var temp = sessionStorage.getItem('domainInfo');
				this.domainInfo = temp ? JSON.parse(temp) : {
					schemeName: '我是记分长'
				};
				if (this.domainInfo) {
					document.title = this.domainInfo.schemeName;
				}
			},

		}
	}
</script>

<style lang="scss">
	.page-content {}
</style>